import img1 from '../../../images/features/ImagingServices.jpg';
import img2 from '../../../images/features/ambulance.jpg';
import img3 from '../../../images/features/laboratory.jpg';
import img4 from '../../../images/features/pharmacy.jpg';
import img5 from '../../../images/features/GeneralSurgery.jpg';


import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';

const AvailableServiceContent = () => {
    const availabeServiceArray = [
        { title: 'Ambulance', img: img2 },
        { title: 'Imaging Services', img: img1 },
        { title: 'Laboratory Services', img: img3 },
        { title: 'General Surgery', img: img5 },
        { title: 'Pharmacy', img: img4 },
    ]
    return (
        <div className="d-flex justify-content-center align-items-center gap-4">

            <Swiper
                spaceBetween={2}
                slidesPerView={4}
                modules={[Navigation, Autoplay]}
                loop={true}
                centeredSlides={true}
                autoplay={{ delay: 2000, disableOnInteraction: false }}
            >
                {
                    availabeServiceArray.map((item) => (
                        <SwiperSlide key={item.title} className='my-2'>
                            <div className="feature-item text-center">
                                <img src={item.img} className="img-fluid" alt="" />
                                <p>{item.title}</p>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
}

export default AvailableServiceContent