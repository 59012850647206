import React from 'react';
import './InfoPage.css';
import { FaClock, FaHospitalSymbol,FaHandHoldingHeart  } from "react-icons/fa";
import { Link } from 'react-router-dom';

const InfoPage = () => {
    return (
        <section className="why-us mt-5 mt-md-0">
            <div className="container">

                <div className="row">
                    <div className="col-lg-6 d-flex align-items-stretch">
                        <div className="content" style={{ textAlign: 'justify' }}>
                            <h3>Why Choose Us?</h3>
                            <p>
                            At WRCHospital, our commitment to your health, comfort, and satisfaction sets us apart. With a compassionate team and cutting-edge facilities, we provide personalized care that ensures your journey to wellness is supported every step of the way. Discover a healthcare experience that goes beyond expectations. Additionally, join us in our new TV Series, Sierra Network Talk To The Camera, featuring insightful conversations with Dr. Gladstone A. Tucker and our patients at Waterloo Rural Community Hospital. Tune in to YouTube for an engaging look into our community and healthcare practices.
                            </p>
                            <div className="text-center">
                                <a href="https://youtu.be/I499v3C2Cpk?si=p8jukFZs05CvO8XC" target="_blank" rel="noopener noreferrer" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-stretch">
                        <div className="icon-boxes d-flex flex-column justify-content-center">
                            <div className="row">
                                <div className="col-xl-6 d-flex align-items-stretch">
                                    <a href="https://www.gofundme.com/f/waterloo-rural-community-hospital-fundraiser?utm_medium=copy_link&utm_source=customer&utm_campaign=p_lico+share-sheet+expWdC/" target="_blank" rel="noreferrer" className="icon-box mt-4 mt-xl-0" style={{ textAlign: 'justify' }}>
                                        <FaHandHoldingHeart className="icon"/>
                                        <h4>Support WRCH</h4>
                                        <small className='text-secondary'><b>Donate Now!</b></small>
                                        <p >your donation today can make a world of difference in providing essential care and comfort to those in need. Together, let's extend a helping hand and bring hope to our community. Your generosity fuels our mission of healing with kindness and compassion.</p>
                                    </a>
                                </div>
                                <div className="col-xl-6 d-flex align-items-stretch">
                                    <Link to="/WellBodi" className="icon-box mt-4 mt-xl-0" style={{ textDecoration: 'none', color: 'inherit', textAlign: 'justify' }}>
                                        <FaHospitalSymbol className="icon"/>
                                        <h4>WELL-BↃDI Plans</h4>
                                        <p>Explore our WELL-BↃDI CASH BOX ASSURANCE PLAN OPTIONS for comprehensive financial coverage tailored to your healthcare needs. From in-house medical services including admissions, imaging, laboratory investigations, maternity services, surgical and medical procedures, to regular medical check-ups every six months, we ensure your well-being is our top priority.</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default InfoPage