import React from 'react';
import './index.css';
import { FaFacebookSquare, FaPhoneAlt, FaEnvelope, FaTwitterSquare  } from "react-icons/fa";

const TopHeader = () => {
    return (
        <div id="topbar" className="d-flex align-items-center fixed-top">
            <div className="container d-flex justify-content-between">
                <div className="contact-info d-flex align-items-center">
                    <FaEnvelope className='contact-icon'/> <a href="mailto:info@wrchospitals.org">info@wrchospitals.org</a>
                    <FaPhoneAlt className='contact-icon'/> <a href="tel: 076431314"> 076431314</a> 
                </div>
                <div className="d-none d-lg-flex social-links align-items-center">
                    <a href="https://twitter.com/WaterlooClinic" target='_blank' rel="noreferrer" className="X"><FaTwitterSquare /></a>
                    <a href="https://www.facebook.com/Waterloo-Rural-Community-Hospital-105997441161305/" target='_blank' rel="noreferrer" className="facebook"><FaFacebookSquare /></a>
                </div>
            </div>
        </div>
    );
};
export default TopHeader;