import React from 'react';
import SubHeader from '../Shared/SubHeader';
import Footer from '../Shared/Footer/Footer';
import Header from '../Shared/Header/Header';
import img from '../../images/img/Diabetes.png';
import img2 from '../../images/img/Elderly.jpg';
import img3 from '../../images/img/BP.png';
import img4 from '../../images/img/Kidney.png';
import img5 from '../../images/img/Women.png';
import img6 from '../../images/img/Labor.png';
import img7 from '../../images/img/Family.png';
import { Link } from 'react-router-dom';
import doctorBg from '../../images/img/doctors-bg.jpg';


const Service = () => {
  const weArePleaseStyle = {
    backgroundColor: "antiquewhite",
    height: "60vh",
    background: `url(${doctorBg}) no-repeat`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    padding: "10px",
    position: "relative",
    marginTop: 100,
    marginBottom: 30
  };

  
  const items = [
    {
      title: "Diabetes Management",
      description: "Diabetes is a pressing issue in Sierra Leone and the broader Sub-Saharan African region. Despite this, national efforts to combat this epidemic remain inadequate. At Waterloo Rural Community Hospital, our medical team recognizes the severe impact of diabetes. We offer comprehensive care for both Type 1 and Type 2 diabetes, with inpatient and outpatient services available. Schedule an appointment today to manage your diabetes effectively.",
      image: img
    },
    {
      title: "Elderly Care",
      description: "Waterloo Community Rural Hospital is dedicated to addressing non-communicable diseases, particularly among the elderly population in Sierra Leone. Our board-certified geriatric doctors specialize in internal medicine and have additional training in geriatric care. We understand the unique challenges faced by older adults, including issues like pain management, falls, memory loss, and chronic conditions such as diabetes and heart disease. Schedule a consultation today for personalized elderly care.",
      image: img2
    },
    {
      title: "High Blood Pressure",
      description: "Cardiovascular diseases, including coronary heart disease, are a significant concern in Sierra Leone, ranking as the fifth leading cause of death. Lifestyle factors such as physical inactivity, unhealthy diet, and tobacco use contribute to this epidemic. At Waterloo Rural Community Hospital, we prioritize educating our community on the importance of a healthy lifestyle to prevent cardiovascular illness. If you or a loved one is affected by high blood pressure or related conditions, schedule an appointment with us to receive expert care and guidance.",
      image: img3
    },
    {
      title: "Kidney Illness",
      description: "Kidney disease is claiming lives in Sierra Leone due to limited access to treatment. Many individuals cannot afford to seek care abroad, exacerbating the situation. Non-communicable diseases like kidney disease have become leading causes of premature death globally. At Waterloo Rural Community Hospital, we understand the importance of accessible treatment for kidney illness. Our facility offers quality dialysis services through Cottontree Pharmacy Group. Don't delay seeking help if you or a loved one is suffering from kidney/renal illness.",
      image: img4
    },
    {
      title: "Women's Health",
      description: "Despite efforts by the Sierra Leonean government to improve maternal and reproductive health services, challenges persist, and maternal mortality rates remain high. Waterloo Hospital is committed to supporting new and expecting mothers. Schedule an appointment today to access quality maternal and newborn health care services and ensure the well-being of yourself or your loved ones.",
      image: img5
    },
    {
      title: "Labor Delivery",
      description: "Our dedicated medical unit at WRCH provides exceptional labor and delivery care, ensuring a positive birthing experience for mothers. With well-trained and certified nurses overseeing the process, we monitor fetal heartbeat and maternal health closely throughout labor. Our delivery nurses are equipped with fetal monitoring devices to detect any signs of distress and promptly involve a doctor if needed. Trust us for expert care during this important journey.",
      image: img6
    },
    {
      title: "Family Planning",
      description: "Waterloo Hospital's Family and Maternal Health Services (FMHS) is committed to supporting families during the crucial first six months after childbirth. Our multidisciplinary team collaborates with community partners to promote maternal and child health. We offer comprehensive support to pregnant women, infants, and their families, ensuring a healthy start for all.",
      image: img7
    }
  ];

  return (
    <>
      <Header />
      <SubHeader title="Service" subtitle="WRCHospital provides a comprehensive range of services to meet your healthcare needs." />

      <div className="container" style={{ marginTop: 50, marginBottom: 50 }}>
        <div className="row">
          {items.map((item, index) => (
            <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
              <div className="card shadow border-0 mb-5">
                <img src={item.image} alt="" className="img-fluid" style={{ maxHeight: '17rem', objectFit: 'cover' }} />
                <div className="p-2" style={{ height: '350px', overflowY: 'auto'  }}> 
                  <h4 className="mt-4 mb-2">{item.title}</h4>
                  <p className="mb-4" style={{ overflow: 'pre-line' }}>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <section style={weArePleaseStyle}>
        <div className="container" style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
          <div className="row">
            <div className="col-lg-7">
              <div className="d-flex align-items-center">
                <div className='mb-4 section-title text-center'>
                  <h2 className='text-uppercase'>We are pleased to offer you the Services</h2>
                  <p className='form-text'>Take the first step towards better health. Click the button below to schedule an appointment now.</p>
                  <Link to={'/doctors'} className="btn-get-started scrollto">Get Started</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Service