import React from 'react';
import './index.css';
import Header from '../Shared/Header/Header';
import Footer from '../Shared/Footer/Footer';
//import ImageHeading from '../../images/doc/Gladstone.png';
//import img2 from '../../images/MissionValues.png';
//import img3 from '../../images/values.png';
//import SubHeader from '../Shared/SubHeader';
//import { useGetAllBlogsQuery } from '../../redux/api/blogApi';
//import { Empty, message } from 'antd';
//import { Link } from 'react-router-dom';
//import { truncate } from '../../utils/truncate';
//import { useGetDoctorsQuery } from '../../redux/api/doctorApi';

const WellBodi = () => {  

    return (
        <>
            <Header />
            <div style={{ height: '180px' }}></div>
            <section id="medical_services_in_house">
                <div className="container">
                    <div className="page-title section">
                        <div className="container">
                            <div className="w-row">
                                <div className="w-col w-col-2 w-col-stack w-hidden-medium w-hidden-small w-hidden-tiny"></div>
                                <div className="w-col w-col-8 w-col-stack">
                                    <h3 className="text-center text-black">WELL-BↃDI CASH BOX ASSURANCE PLAN IN-HOUSE MEDICAL SERVICES</h3>
                                </div>
                                <div className="w-col w-col-2 w-col-stack w-hidden-medium w-hidden-small w-hidden-tiny"></div>
                            </div>
                        </div>
                    </div>
                    <div className="b-b section">
                        <div className="container">
                            <div className="w-row">
                                <div className="w-col w-col-1 w-col-stack w-hidden-medium w-hidden-small w-hidden-tiny"></div>
                                <div className="w-col w-col-10 w-col-stack">
                                    <div className="w-richtext">
                                        <div className="list-group">
                                            <a href="#" className="list-group-item list-group-item-action active">
                                                Covered Services:
                                            </a>
                                            <a href="#" className="list-group-item list-group-item-action">Admissions</a>
                                            <a href="#" className="list-group-item list-group-item-action">Imaging</a>
                                            <a href="#" className="list-group-item list-group-item-action">Laboratory Investigations</a>
                                            <a href="#" className="list-group-item list-group-item-action">Maternity Services</a>
                                            <a href="#" className="list-group-item list-group-item-action">Surgical and Medical Procedures</a>
                                            <a href="#" className="list-group-item list-group-item-action">Medical Check-up every six months</a>
                                            <a href="#" className="list-group-item list-group-item-action active">
                                                Discount:
                                            </a>
                                            <a href="#" className="list-group-item list-group-item-action">10% Discount for Covered Services</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-col w-col-1 w-col-stack w-hidden-medium w-hidden-small w-hidden-tiny"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Table 2: WELL-BↃDI CASH BOX ASSURANCE PLAN AMBULANCE SERVICE */}
            <section id="ambulance_service">
                <div className="container">
                    <div className="page-title section">
                        <div className="container">
                            <div className="w-row">
                                <div className="w-col w-col-2 w-col-stack w-hidden-medium w-hidden-small w-hidden-tiny"></div>
                                <div className="w-col w-col-8 w-col-stack">
                                    <h3 className="text-center text-black">WELL-BↃDI CASH BOX ASSURANCE PLAN AMBULANCE SERVICE</h3>
                                </div>
                                <div className="w-col w-col-2 w-col-stack w-hidden-medium w-hidden-small w-hidden-tiny"></div>
                            </div>
                        </div>
                    </div>
                    <div className="b-b section">
                        <div className="container">
                            <div className="w-row">
                                <div className="w-col w-col-1 w-col-stack w-hidden-medium w-hidden-small w-hidden-tiny"></div>
                                <div className="w-col w-col-10 w-col-stack">
                                    <div className="w-richtext">
                                        <div className="list-group">
                                            <a href="#" className="list-group-item list-group-item-action active">
                                                Covered Services:
                                            </a>
                                            <a href="#" className="list-group-item list-group-item-action">Transport Customers for Prescribed services from WRCH to other Service Providers</a>
                                            <a href="#" className="list-group-item list-group-item-action active">
                                                Discount:
                                            </a>
                                            <a href="#" className="list-group-item list-group-item-action">30% Discount for Ambulance Services</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-col w-col-1 w-col-stack w-hidden-medium w-hidden-small w-hidden-tiny"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Table 3: WELL-BↃDI CASH BOX ASSURANCE PLAN Medical Services Outside WRCH Facility */}
            <section id="medical_services_outside_WRCH_facility">
                <div className="container">
                    <div className="page-title section">
                        <div className="container">
                            <div className="w-row">
                                <div className="w-col w-col-2 w-col-stack w-hidden-medium w-hidden-small w-hidden-tiny"></div>
                                <div className="w-col w-col-8 w-col-stack">
                                    <h3 className="text-center text-black">WELL-BↃDI CASH BOX ASSURANCE PLAN Medical Services Outside WRCH Facility</h3>
                                </div>
                                <div className="w-col w-col-2 w-col-stack w-hidden-medium w-hidden-small w-hidden-tiny"></div>
                            </div>
                        </div>
                    </div>
                    <div className="b-b section">
                        <div className="container">
                            <div className="w-row">
                                <div className="w-col w-col-1 w-col-stack w-hidden-medium w-hidden-small w-hidden-tiny"></div>
                                <div className="w-col w-col-10 w-col-stack">
                                    <div className="w-richtext">
                                        <div className="list-group">
                                            <a href="#" className="list-group-item list-group-item-action active">
                                                Outside Services:
                                            </a>
                                            <a href="#" className="list-group-item list-group-item-action">We collaborate and coordinate your care with other medical specialists and sub-specialist as needed</a>
                                            <a href="#" className="list-group-item list-group-item-action">Dental Services</a>
                                            <a href="#" className="list-group-item list-group-item-action">CT Scan</a>
                                            <a href="#" className="list-group-item list-group-item-action">Dialysis</a>
                                            <a href="#" className="list-group-item list-group-item-action">ICU</a>
                                            <a href="#" className="list-group-item list-group-item-action">Physiotherapy</a>
                                            <a href="#" className="list-group-item list-group-item-action">Sub-specialty Services</a>
                                            <a href="#" className="list-group-item list-group-item-action active">
                                                Payment for Outside Service from Well BↃDI Cash Box:
                                            </a>
                                            <a href="#" className="list-group-item list-group-item-action">50% of cash above your threshold can be paid to outside service providers as needed</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-col w-col-1 w-col-stack w-hidden-medium w-hidden-small w-hidden-tiny"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: '50px' }}></div>
            </section>            
        <Footer />
        </>
    );
}

export default WellBodi;
