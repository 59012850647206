import React from 'react';
import './index.css';
import Header from '../Shared/Header/Header';
import Footer from '../Shared/Footer/Footer';
import ImageHeading from '../../images/doc/Gladstone.png';
import img2 from '../../images/MissionValues.png';
import img3 from '../../images/values.png';
import SubHeader from '../Shared/SubHeader';
import { useGetAllBlogsQuery } from '../../redux/api/blogApi';
import { Empty, message } from 'antd';
import { Link } from 'react-router-dom';
import { truncate } from '../../utils/truncate';
import { useGetDoctorsQuery } from '../../redux/api/doctorApi';

const About = () => {
    const { data, isError, isLoading } = useGetAllBlogsQuery({ limit: 4 });
    const { data: doctorData, isLoading: DoctorIsLoading, isError: doctorIsError } = useGetDoctorsQuery({ limit: 4 });

    const blogData = data?.blogs;
    const doctors = doctorData?.doctors;

    let doctorContent = null;
    if (!DoctorIsLoading && doctorIsError) doctorContent = <div>Something Went Wrong !</div>
    if (!DoctorIsLoading && !doctorIsError && doctors?.length === 0) doctorContent = <div><Empty /></div>
    if (!DoctorIsLoading && !doctorIsError && doctors?.length > 0) doctorContent =
        <>
            {doctors && doctors.map((item, id) => (
                <div className="col-lg-3 col-md-6 col-sm-6" key={id + item.id}>
                    <div className="card shadow border-0 mb-5 mb-lg-0">
                        {item.img && <img src={item.img} className="img-fluid w-100" alt="" style={{ height: '200px', objectFit: 'cover', borderRadius: '15px' }} />}
                        <div className="p-2">
                            <h4 className="mt-4 mb-0" style={{ color: '#223a66' }}><a>{item?.firstName + ' ' + item?.lastName}</a></h4>
                            <p>{item?.designation}</p>
                        </div>
                    </div>
                </div>
            ))}
        </>

    let content = null;
    if (!isLoading && isError) content = <div>{message.error('Something went Wrong!')}</div>
    if (!isLoading && !isError && blogData?.length === 0) content = <Empty />
    if (!isLoading && !isError && blogData?.length > 0) content =
        <>
            {
                blogData && blogData?.map((item, id) => (
                    <div className="col-lg-3 col-md-6" key={id + item.id}>
                        <div className="card shadow border-0 mb-5 mb-lg-0">
                            <img src={item?.img} alt="blog Image" width={300} height={200} className="w-100  rounded-top image-hover" style={{ objectFit: 'contain' }} />

                            <div className='p-2'>
                                <Link to={`/blog/${item?.id}`}>
                                    <h6 className="text-start mb-1 text-capitalize" style={{ color: '#223a66' }}>{truncate(item?.title, 40)}</h6>
                                </Link>
                                <div className="px-2">
                                    <p className="form-text text-start text-capitalize">{truncate(item?.description, 80)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    return (
        <>
            <Header />
            <SubHeader title="about us" subtitle="Welcome to WRCHospial, where we're dedicated to excellence in healthcare. Discover our rich history, mission, and values below." />
            <div className="container" style={{ marginBottom: 30, marginTop: 30 }}>
                <div className="row p-5">

                    <div className="col-lg-5 col-md-6 col-sm-12" style={{ marginBottom: 10, marginTop: 15 }}>
                        <img src={ImageHeading} alt="" className="img-fluid rounded shadow" style={{ height: '380px', width: '350px', objectFit: 'cover', borderRadius: '10px'  }}/>
                    </div>

                    <div className="col-lg-7">
                        <div className='section-title text-center'>
                            <h2 className='text-uppercase'>OUR HISTORY</h2>
                            <p className='form-text m-0'><b>Dr. Gladstone Tucker</b></p>
                        </div>
                        <p className='mt-3'style={{ textAlign: 'justify' }}>The Gladstone Medical Foundation was borne out of the desire to help control the alarming increase in Non-Communicable Diseases (NCDs) in Sierra Leone. The NCDs and conditions represent a significant burden in Sierra Leone. However, there is lack of up- to date information on the prevalence of NCDs such as hypertension and other cardiovascular diseases, diabetes and mellitus and sick-cell diseases. The Sierra Leone Health care system has its traditional approach in the handling of NCDs. The Gladstone Medical Foundation has therefore developed a paradigm shift approach specifically at the rural level to shift from the conventional mode of addressing NCDs from the tertiary care level to primary care with specific focus on risk reduction and prevention which is essential for addressing NCDs in poor rural communities in Sierra Leone but difficult approach for their prevention and control. The Gladstone Medical Foundation is established to comprehensively treat NCDs at primary care level rather than at the secondary level.</p>
                    </div>
                </div>
            </div>

            <div className="container" style={{ marginBottom: 10, marginTop: 10 }}>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className='section-title text-center'>
                            <h2 className='text-uppercase'>Our Mission</h2>
                            <p className='form-text m-0'style={{ textAlign: 'justify' }}>To establish a Health Care System that focuses on Non–Communicable Diseases (NCDs) while addressing prevalent communicable diseases in the region.</p>
                        </div>
                        <div className='section-title text-center'>
                            <h2 className='text-uppercase'>Our Vision</h2>
                            <p className='form-text m-0'style={{ textAlign: 'justify' }}><b>Provide quality and affordable health care through excellent customer services.</b><br />The medical staff and administration will maintain policies that honor each patient’s right to “Privacy” and to ensure that every patient and their family are treated with “RESPECT, DIGNITY and EMPATHY”.To educate each patient on their diagnosis and treatment plan. To ensure that hospital staff explains the type of service and the reasoning for each procedure being performed to all patients. That our medical and surgical staff obtain consent for all surgical procedures from the patient or their surrogate prior to performance of such procedure except in life threatening situations when a poor outcome is imminent without such procedure. That all patients are seen every three to six months for health maintenance. Such visits are to address risk factors associated with HYPERTENSION, DIABETES MELLITUS, HIGH CHOLESTEROAL, CORONARY ARTERY DISEASE (HEART ATTACK) and STROKE.</p>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-9">
                                <div className="award-img">
                                    <img src={img2} alt="" className="img-fluid" style={{ height: '400px', width: '430px', objectFit: 'cover', borderRadius: '10px'  }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container" style={{ marginBottom: 10, marginTop: 10 }}>
                <div className="row">

                    <div className="col-lg-12">
                        <div className='section-title text-center'>
                            <h2 className='text-uppercase'>Our Values</h2>
                            <p className='form-text m-0'style={{ textAlign: 'justify' }}>
                                <b>Prevention and Self-Management Care</b><br />
                                Access to health care can be difficult in rural areas. It is also much more expensive to treat sickness than prevent illness where possible. Focus efforts on wellness education and illness prevention.<br /><br />
                                <b>Provide Patient-Centered Health Care Services</b><br />
                                Policy development, program planning, and service delivery must ensure a focus on patient care is Kept at the forefront of health care decisions.<br /><br />
                                <b>Engage in Partnerships and Coordinated Efforts</b><br />
                                Opportunities for collaboration are currently underutilized at the community, regional and provincial levels. Regulated health care partners specializing in health prevention strategies are not networked or made use of.<br /><br />
                                <b>Innovative Service Delivery to Rural Communities</b><br />
                                Address organizational, technical, transportation, and implementation barriers to improve the delivery of services to rural Sierra Leone.<br /><br />
                                <b>Human Resource Development</b><br />
                                Barriers and challenges related to Human Resources within the health system need to be addressed. From the education and training of practitioners to recruitment and retention challenges in rural areas; from pay equity issues, to utilizing providers in appropriate circumstances and to their full scope of practice - addressing these challenges must be part of any strategy aimed at benefiting rural communities.<br /><br />
                                <b>Engage in Research</b><br />
                                The Foundation will engage in research to broaden and deepen rural health evidence-based and make it available to practitioners and health networks.<br /><br />
                                <b>Utilize Information Technology</b><br />
                                Utilization of various Information Technology tools and services to support this initiative will go a long way to train, educate, diagnose, research, collaborate, treat, and prevent diseases. E.g. Use of drones to deliver medications, video to educate, train and diagnose diseases with other partners.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12" style={{ marginBottom: 10, marginTop: 10 }}>
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 col-sm-9">
                                <div className="award-img">
                                    <img src={img3} alt="" className="img-fluid" style={{ height: '400px', width: '430px', objectFit: 'cover', borderRadius: '10px'  }} />
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
            
            <Footer />
        </>
    )
}

export default About